import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  ToggleButton,
  Button,
  useMediaQuery,
  Tooltip,
  Grow,
} from '@mui/material';
import { IoDiamond } from 'react-icons/io5';
import { GiEmerald } from 'react-icons/gi';
import { useTheme } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const plans = [
  {
    name: 'Diamond',
    monthly: '$4',
    annual: '$45',
    icon: <IoDiamond size={40} color="#5ED49B" />,
    perks: [
      'All platinum features',
      'Priority event booking',
      'Personalized hub',
      'Customer support',
      'Exclusive webinars',
      'Early access to new features',
    ],
  },
  {
    name: 'Emerald',
    monthly: '$8',
    annual: '$90',
    icon: <GiEmerald size={40} color="#5ED49B" />,
    perks: [
      'All diamond features',
      'Ultimate event access',
      'VIP Event invitations',
      '24/7 Support',
      'Dedicated account manager',
      'Custom integrations',
    ],
  },
];

const pricingOptions = [
  { value: 'annual', label: 'Annual' },
  { value: 'monthly', label: 'Monthly' },
];

const useToggleButtonStyle = (selected) => ({
  flex: 1,
  backgroundColor: selected ? '#5ED49B' : '#fff',
  color: selected ? '#fff' : '#5ED49B',
  border: 'none',
  borderRadius: 0,
  padding: { xs: '6px 0', sm: '8px 0' },
  '&:hover': {
    backgroundColor: selected ? '#4CAF50' : '#f0f0f0',
  },
  transition: 'background-color 0.3s, color 0.3s',
  fontWeight: 600,
  fontSize: { xs: '14px', sm: '16px' },
  textTransform: 'none',
  minWidth: 0,
});

const PlanCard = ({ plan, isSelected, onSelect, pricingType }) => (
  <Grow in timeout={500}>
    <Paper
      onClick={onSelect}
      sx={{
        backgroundColor: '#fff',
        borderRadius: '12px',
        padding: '16px 12px 12px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        border: isSelected ? '2px solid #5ED49B' : '1px solid transparent',
        transform: isSelected ? 'scale(1.02)' : 'scale(1)',
        transition: 'transform 0.3s, border 0.3s',
        cursor: 'pointer',
        width: '100%',
        boxSizing: 'border-box',
      }}
      elevation={isSelected ? 6 : 3}
    >
      <Box>
        <Tooltip title={plan.name} arrow>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>{plan.icon}</Box>
        </Tooltip>
        <Typography
          variant="h6"
          sx={{
            color: '#5ED49B',
            fontWeight: '700',
            wordBreak: 'break-word',
          }}
        >
          {plan.name}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: '#18453B',
            fontWeight: '600',
            mb: { xs: '8px', sm: '4px' },
            wordBreak: 'break-word',
          }}
        >
          <Box component="span" sx={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
            {pricingType === 'monthly' ? plan.monthly : plan.annual}
          </Box>{' '}
          <Box component="span" sx={{ fontSize: '1.5rem' }}>
            / {pricingType === 'monthly' ? 'month' : 'year'}
          </Box>
        </Typography>
        <Box sx={{ textAlign: 'left', mb: { xs: 2, sm: 2 } }}>
          {plan.perks.map((perk, i) => (
            <Typography
              key={i}
              sx={{
                fontSize: '14px',
                color: '#555',
                mb: 0.75,
                display: 'flex',
                alignItems: 'center',
                wordBreak: 'break-word',
              }}
            >
              <CheckCircleIcon sx={{ color: '#5ED49B', mr: 0.5, fontSize: '14px' }} />
              {perk}
            </Typography>
          ))}
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#5ED49B',
            color: '#fff',
            borderRadius: '30px',
            padding: { xs: '10px 20px', sm: '8px 16px' },
            fontWeight: '600',
            textTransform: 'none',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            '&:hover': { backgroundColor: '#4CAF50' },
            alignSelf: 'center',
            fontSize: '14px',
            mb: { xs: 0, sm: 0 },
            width: { xs: '50%', sm: 'auto' },
          }}
          onClick={() => onSubscribe(plan)}
        >
          Subscribe & Pay
        </Button>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          top: '12px',
          right: '12px',
          backgroundColor: isSelected ? '#5ED49B' : '#fff',
          color: isSelected ? '#fff' : '#5ED49B',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
        }}
      >
        {isSelected ? <CheckCircleIcon fontSize="small" /> : <RadioButtonUncheckedIcon fontSize="small" />}
      </Box>
    </Paper>
  </Grow>
);

const UpgradePage = () => {
  const [pricingType, setPricingType] = useState('annual');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handlePricingToggle = (event, newType) => {
    if (newType) setPricingType(newType);
  };

  const handleSelectPlan = (index) => {
    setSelectedPlan(index);
  };

  const handleSubscribe = (plan) => {
    console.log('Subscribe to:', plan.name);
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        padding: isMobile ? '10px' : '20px 30px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        boxSizing: 'border-box',
        overflow: { xs: 'auto', sm: 'hidden' },
        height: { sm: '100vh' },
      }}
    >
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        sx={{
          mb: isMobile ? '10px' : '10px',
          fontWeight: 'bold',
          color: '#18453B',
          textShadow: '1px 1px 2px rgba(0,0,0,0.2)',
        }}
      >
        Upgrade Your Experience
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '1px',
          mb: isMobile ? '15px' : '15px',
          borderRadius: '50px',
          overflow: 'hidden',
          border: '1px solid #5ED49B',
          width: isMobile ? '50%' : '200px',
        }}
      >
        {pricingOptions.map((option) => (
          <ToggleButton
            key={option.value}
            value={option.value}
            selected={pricingType === option.value}
            onChange={handlePricingToggle}
            sx={useToggleButtonStyle(pricingType === option.value)}
          >
            {option.label}
          </ToggleButton>
        ))}
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent="center"
        sx={{
          maxWidth: '700px',
          width: '100%',
        }}
      >
        {plans.map((plan, index) => (
          <Grid item xs={12} sm={6} key={plan.name}>
            <PlanCard
              plan={plan}
              isSelected={selectedPlan === index}
              onSelect={() => handleSelectPlan(index)}
              pricingType={pricingType}
              onSubscribe={handleSubscribe}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UpgradePage;