import React from 'react';
import { Grid, Typography, Paper, Container } from '@mui/material';

const LibraryPage = () => (
  <Container sx={{ padding: '20px' }}>
    <Grid container spacing={2}>
      {['Event 1', 'Event 2', 'Event 3'].map((event, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Paper sx={eventBoxStyle} elevation={3}>
            <Typography variant="h6" component="div">
              {event}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  </Container>
);

const eventBoxStyle = {
  backgroundColor: '#f5f5f5',
  padding: '20px',
  textAlign: 'center',
  borderRadius: '8px',
};

export default LibraryPage;