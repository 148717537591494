import React, { useState, useEffect } from 'react';
import { Grid, Typography, IconButton, Container } from '@mui/material';
import { ArrowDownward as DownArrowIcon } from '@mui/icons-material';

const ExplorePage = () => {
  const [events, setEvents] = useState([]);
  const [visibleCount, setVisibleCount] = useState(6);

  useEffect(() => {
    const eventList = Array.from({ length: 40 }, (_, index) => ({
      id: index,
      title: `Experience ${index + 1}`,
      date: 'July 31, 2024',
      location: `Location ${index + 1}`,
    }));
    setEvents(eventList);
  }, []);

  return (
    <Container sx={{ padding: '20px' }}>
      <Grid container spacing={2}>
        {events.slice(0, visibleCount).map((event) => (
          <Grid item xs={12} sm={6} key={event.id}>
            <div style={eventBoxStyle}>
              <Typography variant="h6">{event.title}</Typography>
              <Typography variant="body2">{event.date}</Typography>
              <Typography variant="body2">{event.location}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
      {visibleCount < events.length && (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <IconButton onClick={() => setVisibleCount(visibleCount + 6)} sx={arrowButtonStyle}>
            <DownArrowIcon />
          </IconButton>
        </div>
      )}
    </Container>
  );
};

const eventBoxStyle = {
  backgroundColor: '#e0e0e0',
  borderRadius: '8px',
  padding: '20px',
  textAlign: 'center',
};

const arrowButtonStyle = {
  backgroundColor: '#18453B',
  color: 'white',
  borderRadius: '50%',
  '&:hover': {
    backgroundColor: '#123F2F',
  },
};

export default ExplorePage;