import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PiNotebookFill } from 'react-icons/pi';
import { IoSettings } from 'react-icons/io5';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompass, faPlus, faBookmark, faUser } from '@fortawesome/free-solid-svg-icons';

const HomePage = () => {
  const navigate = useNavigate();

  const pages = [
    { label: 'Classes', icon: <PiNotebookFill size={40} />, path: '/classes' },
    { label: 'Explore', icon: <FontAwesomeIcon icon={faCompass} size="2x" />, path: '/explore' },
    { label: 'Create', icon: <FontAwesomeIcon icon={faPlus} size="2x" />, path: '/create' },
    { label: 'Library', icon: <FontAwesomeIcon icon={faBookmark} size="2x" />, path: '/library' },
    { label: 'Profile', icon: <FontAwesomeIcon icon={faUser} size="2x" />, path: '/profile' },
    { label: 'Settings', icon: <IoSettings size={40} />, path: '/settings' },
  ];

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        {pages.map((page, index) => (
          <Grid item xs={6} sm={4} md={3} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Paper
              elevation={3}
              onClick={() => navigate(page.path)}
              sx={{
                width: '100%',
                padding: '30px',
                textAlign: 'center',
                cursor: 'pointer',
                borderRadius: '12px',
                backgroundColor: '#18453B',
                color: 'white',
                '&:hover': {
                  transform: 'scale(1.08)',
                  boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              {page.icon}
              <Typography variant="h5">{page.label}</Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default HomePage;