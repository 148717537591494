import React from 'react';
import { Box, IconButton } from '@mui/material';
import { Add as AddIcon, Search as SearchIcon, ArrowDownward as DownArrowIcon } from '@mui/icons-material';

const Footer = () => (
  <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'space-around', padding: '10px', backgroundColor: '#18453B' }}>
    <IconButton sx={{ color: 'white' }}><AddIcon /></IconButton>
    <IconButton sx={{ color: 'white' }}><SearchIcon /></IconButton>
    <IconButton sx={{ color: 'white' }}><DownArrowIcon /></IconButton>
  </Box>
);

export default Footer;