import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
  useMediaQuery,
  Typography,
} from '@mui/material';
import { HiViewList } from 'react-icons/hi';
import { GoHomeFill, GoHome } from 'react-icons/go';
import {
  MdOutlineExplore,
  MdExplore,
  MdOutlineLibraryBooks,
  MdLibraryBooks,
  MdOutlineAdd,
} from 'react-icons/md';
import {
  PiArrowFatLineUpLight,
  PiArrowFatLineUpFill,
} from 'react-icons/pi';
import { useNavigate, useLocation } from 'react-router-dom';

const LeftNavBar = ({ isOpen, toggleDrawer, children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width:600px)');

  const isHome = location.pathname === '/home';
  const isExplore = location.pathname === '/explore';
  const isLibrary = location.pathname === '/library';
  const isUpgrade = location.pathname === '/upgrade';

  const items = [
    {
      icon: isHome ? <GoHomeFill size={28} /> : <GoHome size={28} />,
      label: 'Home',
      path: '/home',
      selected: isHome,
    },
    {
      icon: isExplore ? <MdExplore size={28} /> : <MdOutlineExplore size={28} />,
      label: 'Explore',
      path: '/explore',
      selected: isExplore,
    },
    {
      icon: isLibrary ? <MdLibraryBooks size={28} /> : <MdOutlineLibraryBooks size={28} />,
      label: 'Library',
      path: '/library',
      selected: isLibrary,
    },
    {
      icon: isUpgrade ? (
        <PiArrowFatLineUpFill size={28} />
      ) : (
        <PiArrowFatLineUpLight size={28} />
      ),
      label: 'Upgrade',
      path: '/upgrade',
      selected: isUpgrade,
    },
  ];

  const drawerWidth = isOpen ? 280 : 80;

  return (
    <div style={{ display: 'flex' }}>
      <Drawer
        variant={isMobile ? 'temporary' : 'permanent'}
        open={!isMobile || isOpen}
        onClose={isMobile ? toggleDrawer : undefined}
        ModalProps={{ keepMounted: true }}
        PaperProps={{
          sx: {
            width: drawerWidth,
            backgroundColor: '#18453B',
            color: 'white',
            overflowX: 'hidden',
            transition: 'width 0.3s',
          },
        }}
      >
        <List disablePadding>
          {/* Toggle Button */}
          <ListItem
            sx={{
              justifyContent: 'center',
              paddingY: 1,
              '&:hover': { backgroundColor: 'transparent' },
            }}
          >
            <IconButton
              onClick={toggleDrawer}
              sx={{
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  borderRadius: '50%',
                },
              }}
            >
              <HiViewList size={32} />
            </IconButton>
          </ListItem>

          {/* Navigation Items */}
          {items.map((item) => (
            <ListItem
              button
              key={item.path}
              onClick={() => navigate(item.path)}
              sx={{
                justifyContent: isOpen ? 'flex-start' : 'center',
                alignItems: 'center',
                paddingY: 1,
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
                backgroundColor: item.selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: isOpen ? '30px' : '0',
                }}
              >
                {item.icon}
              </div>
              {isOpen && (
                <ListItemText
                  primary={item.label}
                  sx={{
                    marginLeft: '20px',
                    fontWeight: item.selected ? 'bold' : 'normal',
                  }}
                />
              )}
            </ListItem>
          ))}

          <Divider sx={{ backgroundColor: 'white', marginY: '10px' }} />

          {/* Create Button */}
          <ListItem
            button
            onClick={() => {
              // Handle Create action here
            }}
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              paddingY: 1,
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.1)' },
            }}
          >
            <IconButton
              sx={{
                color: '#18453B',
                backgroundColor: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255,255,255,0.9)',
                },
                borderRadius: '50%',
                padding: '5px',
              }}
            >
              <MdOutlineAdd size={28} />
            </IconButton>
            {isOpen && (
              <ListItemText
                primary="Create"
                sx={{
                  marginLeft: '15px',
                  color: 'white',
                }}
              />
            )}
          </ListItem>
        </List>
      </Drawer>

      {/* Main Content */}
      <div
        style={{
          flexGrow: 1,
          paddingLeft: isMobile ? '0' : `${drawerWidth}px`,
          transition: 'padding-left 0.3s',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default LeftNavBar;