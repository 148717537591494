import React, { useState } from 'react'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LeftNavBar from './components/LeftNavBar';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ExplorePage from './pages/ExplorePage';
import LibraryPage from './pages/LibraryPage';
import UpgradePage from './pages/UpgradePage';

const App = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  const toggleDrawer = () => setDrawerOpen(!isDrawerOpen);

  return (
    <Router>
      <LeftNavBar isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/explore" element={<ExplorePage />} />
          <Route path="/library" element={<LibraryPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
        </Routes>
        <Footer />
      </LeftNavBar>
    </Router>
  );
};

export default App;